export const fieldTypes = {
    TEXT: 'Text',
    NUMBER: 'Number',
    BOOL: 'Boolean',
    SELECT: 'Select'
}

export const caseStates = {
    NEW: 'new',
    RETURNED: 'returned',
    IN_WORK: 'inWork',
    PROCESSING_COMPLETED: 'processingCompleted',
    CASE_CANCEL: 'caseCancel',
    ARCHIVE: 'archive',
}

export const displayModes = {
    CARD_AND_GRID: 0,
    GRID: 1,
    CARD: 2
}

export const PAGE_SIZE = 50;
export const CASES_AUTO_REFRESH_TIME = 30000;
export const AUTO_REFRESH_TIME = 10000;
export const TOAST_AUTO_CLOSE_TIME = 3000;

export const DEFAULT_REPRESENTATION_TYPE_CODE = 7
export const REPRESENTATION_DICTIONARY_NAME = 'views'
export const MAX_FILE_SIZE_NAME = 'maxFileSize'
export const FIELD_MATRIX_NAME = 'fieldMatrix'
export const DICTIONARIES_SETTINGS_NAME = 'dictionariesSettings'
export const BACKLIGHT_CONSTRUCTOR_NAME = 'backlightConstructor'
export const GATE_REVIEWS_NAME = 'gateReviews'

export const fieldSettingsValues = {
    HIDDEN: 'hidden',
    SHOW: 'show',
    EDIT: 'edit'
}
export const weekDays = [
    { name: 'monday', value: 'monday' },
    { name: 'tuesday', value: 'tuesday' },
    { name: 'wednesday', value: 'wednesday' },
    { name: 'thursday', value: 'thursday' },
    { name: 'friday', value: 'friday' },
    { name: 'saturday', value: 'saturday' },
    { name: 'sunday', value: 'sunday' }
];