import React from 'react';
import {Message} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {caseStates} from "../../../constants/options";

const Messages = ({errorText, form}) => {
    const {t} = useTranslation();

    const states = [
        caseStates.RETURNED,
        caseStates.PROCESSING_COMPLETED
    ]

    const result = form.resultType?.value;
    const partialRejectedName = 'partialRejected';
    const confirmedName = 'confirmed';

    const warnings = (state) => {
        switch (state) {
            case states[0]:
                return <>
                    <Message color="yellow">{t(state)}</Message>
                    <p>
                        {t('sBCommentOnReturn')}: <b className="history-comment">{form.resultComment}</b>
                    </p>
                </>;
            case states[1]:
                return <>
                    <Message color={result === confirmedName ? "green" : "red"}>{t(result)}</Message>
                    {result === partialRejectedName && <>
                        <p>
                            {t('partialFailureSecurityCouncilComment')}: <b className="history-comment">{form.resultComment}</b>
                        </p>
                    </>}
                </>
            default: return ''
        }
    }

    return <div className='security__messages'>
        {
            (form.status && states.includes(form.status.value)) && <div className="margin-bottom-15">
                {
                    warnings(form.status.value)
                }
            </div>
        }
        {errorText && <Message error content={t(errorText)}/>}
    </div>
};

export default Messages;