import React from 'react';
import TableBlock from "../../components/TableBlock/TableBlock";
import {
    useDeleteMutation,
    useExportMutation,
    useLazyGetCountersQuery,
    useLazyGetIdsQuery,
    useLazyGetListQuery,
} from "../../store/api/gridApi";
import {securityGrids, securityServiceGridName} from "../../constants/security";
import {Checkbox} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import SecurityModal from "./SecurityModal";
import {useSelector} from "react-redux";
import {gridFieldsSelector, gridSelector} from "../../store/slices/userSlice";
import {CASES_AUTO_REFRESH_TIME, displayModes} from "../../constants/options";

const SecurityPage = ({}) => {
    const {t} = useTranslation();

    const settings = securityGrids[0];

    const gridData = useSelector(state => gridSelector(state, securityServiceGridName));
    const gridColumns = useSelector(state => gridFieldsSelector(state, securityServiceGridName, displayModes.GRID));

    const editBtn = (btn, refresh, id, mode) => {
        return (
            <SecurityModal
                key={id}
                id={id}
                onClose={refresh}
                isService={id && mode !== 4}
            >
                {btn}
            </SecurityModal>
        );
    }

    const checkboxTable = (filters, setFilters, _, mode, refCustomFilters) => {
        const setCheck = (e, {name, checked}) => {
            setFilters(filters => ({
                ...filters,
                gridSubMode: checked ? name : null
            }))
        }

        const checkboxes = []

        checkboxes.push({
            name: '0',
            label: 'showAll',
            className: 'padding-right-15'
        })

        if (![3, 4].includes(mode)) checkboxes.push(
            {
                name: '1',
                label: 'closeToDelay',
                className: 'color-orange padding-right-15'
            },
            {
                name: '2',
                label: 'overdue',
                className: 'color-red'
            },
        )

        return (
            <div ref={refCustomFilters}>
                {
                    checkboxes.map(({name, label, className}, i) => <Checkbox
                        key={name}
                        className={className}
                        name={name}
                        checked={filters.gridSubMode === name}
                        label={t(label)}
                        onChange={setCheck}
                    />)
                }
            </div>
        );
    };

    return (settings && gridData) && <TableBlock
        key={securityServiceGridName}
        settings={{
            ...settings,
            customFilters: checkboxTable,
            ...gridData,
            defaultMode: 0,
            columns: gridColumns,
            autoRefreshTime: CASES_AUTO_REFRESH_TIME
        }}
        editBtn={editBtn}
        deleteRow={useDeleteMutation}
        getCountersQuery={useLazyGetCountersQuery}
        getListQuery={useLazyGetListQuery}
        getIdsQuery={useLazyGetIdsQuery}
        exportToExcelFunc={useExportMutation}
        withBacklights
        withFooter
    />
};

export default SecurityPage;