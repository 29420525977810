import React from "react";
import {TextArea} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const TextAreaEdit = ({value, name, onChange, isDisabled, noLabel, rows = 3, readOnly, label, isRequired, maxLength}) => {
    const {t} = useTranslation();

    return (
        <>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : null}>{t(label || name)} {isRequired && '*'}</label> : null}
            <TextArea
                maxLength={maxLength}
                readOnly={isDisabled || readOnly}
                rows={rows}
                name={name}
                value={value || ""}
                onChange={onChange}
            />
        </>
    );
};
export default TextAreaEdit;